import catImage from '../../assets/images/cat.jpg';
import { Link } from 'react-router-dom';
import SectionHeader from '../../Components/SectionHeader';

const NotFound = () => {
  return (
    <section className='flex flex-col space-y-10 items-center justify-center m-10'>
      <SectionHeader>That page was not found!</SectionHeader>
      <div className='divider'></div>
      <h2 className='text-xl font-bold'>One of two things happened</h2>
      <p className='text-l font-bold'>
        1 - You entered a bad URL.{' '}
        <span className='text-red-500 italic'>Don't do that.</span>
      </p>
      <p>OR</p>
      <p className='text-l font-bold'>
        2 - The cat deleted that page.{' '}
        <span className='text-sm font-normal'>
          Are you really going to blame this cat?
        </span>{' '}
        <span className='text-sm font-normal italic'>Really? Truly?</span>
      </p>
      <img
        src={catImage}
        alt='a white cat sitting on a mousepad by a keyboard'
        className='w-100 h-100'
      />
      <div className='divider'></div>
      <button className='btn hover:btn-ghost hover:scale-95'>
        <Link to={'/'}>Go Home</Link>
      </button>
    </section>
  );
};

export default NotFound;
