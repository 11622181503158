import { FaCodeBranch, FaHardHat, FaJava, FaJsSquare } from 'react-icons/fa';
import SectionHeader from '../../Components/SectionHeader';
import SubSectionHeader from '../../Components/SubSectionHeader';

const Story = () => {
  return (
    <>
      {/* ********** ********** Outer Section, About ********** ********** */}
      <section className='px-4'>
        <SectionHeader customClasses='mb-4'>The Story of p.js</SectionHeader>
        <div className='flex flex-col space-y-8 mt-8'>
          {/* ---------- Inner Section, 1 of 4, University ---------- */}
          <section>
            <div className='flex'>
              <FaHardHat className='mr-2 text-3xl text-red-400' />
              <SubSectionHeader customClasses='text-white mb-2'>
                Hardware to Software
              </SubSectionHeader>
            </div>
            <p>
              My original college major was mechanical engineering. While
              interesting and enjoyable, I took a programming course and was
              fascinated. I knew I had to switch majors! I graduated with a
              bachelor's of science in computer science.
            </p>
          </section>
          {/* ---------- Inner Section, 2 of 4, First Job ---------- */}
          <section>
            <div className='flex'>
              <FaJava className='mr-2 text-3xl text-yellow-400' />
              <SubSectionHeader customClasses='text-white mb-2'>
                A Cup of Java
              </SubSectionHeader>
            </div>
            <p>
              My first job was mostly Java. I learned the typical Java stack -
              Servlets, JSP, Spring, and Hibernate. I also learned Spring Boot,
              which made the process of writing Java applications much better!
            </p>
          </section>
          {/* ---------- Inner Section, 3 of 4, p.js ---------- */}
          <section>
            <div className='flex'>
              <FaJsSquare className='mr-2 text-3xl text-green-400' />
              <SubSectionHeader customClasses='text-white mb-2'>
                Reacting to JavaScript!
              </SubSectionHeader>
            </div>
            <p>
              One day I saw a colleague filling in a form,{' '}
              <span className='italic'>
                and the rest of the screen updated, like magic
              </span>
              . It was AngularJS, and I decided to learn it that very day. As
              angular.js faded in favor of react, I learned react with and
              without typescript, and got some experience with Angular as well.
            </p>
          </section>
          {/* ---------- Inner Section, 4 of 4, fullstack ---------- */}
          <section>
            <div className='flex'>
              <FaCodeBranch className='mr-2 text-3xl text-blue-400' />
              <SubSectionHeader customClasses='text-white mb-2'>
                Current Role
              </SubSectionHeader>
            </div>
            <p>
              As of 2023 my current role is Java backend with AWS cloud. I
              dabble in frontend (Angular/React) as needed.
            </p>
          </section>
        </div>
      </section>
    </>
  );
};

export default Story;
