import Recommendations from './Recommendation';

const Resume = () => {
  return (
    <>
      {/* ---------- Recommendations ---------- */}
      <div className='mt-10'>
        <Recommendations />
      </div>
    </>
  );
};

export default Resume;
