import masonImg from '../../assets/images/mason.jpg';
import ethanImg from '../../assets/images/ethan.jpg';
import sarahImg from '../../assets/images/sarah.jpg';

const Personal = () => {
  return (
    <div className='flex flex-col items-center justify-center space-y-10 mt-10'>
      {/* ---------- sarah ---------- */}
      <div
        className='w-90 max-w-md tooltip tooltip-bottom'
        data-tip='We have been together since high school!'
      >
        <h2 className='text-l md:text-2xl my-2 text-center'>
          My wife Sarah and I at the aquarium.
        </h2>
        <img src={sarahImg} alt='Paul and his wife Sarah at the aquarium' />
      </div>
      <div className='divider'></div>
      {/* ---------- mason ---------- */}
      <div
        className='w-90 max-w-md tooltip tooltip-bottom'
        data-tip='Mason is 4 in this photo, and turns 5 in Nov 2023.'
      >
        <h2 className='text-l md:text-2xl my-2 text-center'>
          My son Mason at the farm, on a hayride.
        </h2>
        <img src={masonImg} alt='Son Mason at the farm on a hayride' />
      </div>
      {/* ---------- ethan ---------- */}
      <div className='divider'></div>
      <div
        className='w-90 max-w-md tooltip tooltip-bottom'
        data-tip='Ethan is 11 months in this photo, and turns 1 in May 2023.'
      >
        <h2 className='text-l md:text-2xl my-2 text-center'>
          My son Ethan at the farm, in a tulip field.
        </h2>
        <img src={ethanImg} alt='Son Ethan at the farm in a tulip field' />
      </div>
    </div>
  );
};

export default Personal;
