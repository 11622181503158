/**
 * TextBoldBlueProps
 * Custom classes allows for additional classes to be added.
 * Children is the standard props.children.
 */
type Props = {
  customClasses?: string;
  children: React.ReactNode;
};

/**
 * Text Bold Blue
 * A span element that turns text into a bold, darker shade of blue that stands
 * out against the regular text theme.
 * @param props, defined above
 * @returns
 */
const TextBoldBlue: React.FC<Props> = ({ customClasses = '', children }) => {
  return (
    <span className={`font-bold text-blue-400 ${customClasses}`}>
      {children}
    </span>
  );
};

export default TextBoldBlue;
