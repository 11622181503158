import { FaClock, FaMedal, FaMoneyBill, FaSmileBeam } from 'react-icons/fa';
import SectionHeader from '../../Components/SectionHeader';

const Goals = () => {
  return (
    <section className='px-4'>
      <SectionHeader customClasses='my-2'>Goals</SectionHeader>
      <p>
        My goal as a developer is to{' '}
        <span className='italic font-bold text-blue-400'>
          create software that has a positive impact on people's lives
        </span>
        .
      </p>
      <p className='my-2'>Software that helps people:</p>
      <div className='flex flex-col space-y-2'>
        <div className='flex ml-2'>
          <FaClock className='mr-2 text-xl' /> save time
        </div>
        <div className='flex ml-2'>
          <FaMoneyBill className='mr-2 text-xl' /> make or save money
        </div>
        <div className='flex ml-2'>
          <FaMedal className='mr-2 text-xl' /> achieve their goals
        </div>
        <div className='flex ml-2'>
          <FaSmileBeam className='mr-2 text-xl' /> enjoy a pleasant escape
        </div>
      </div>
    </section>
  );
};

export default Goals;
