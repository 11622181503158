import { FaEnvelope } from 'react-icons/fa';
import SectionHeader from '../../Components/SectionHeader';

const Email = () => {
  return (
    <section>
      <div className='flex px-4'>
        <SectionHeader>Email</SectionHeader>
        <FaEnvelope className='text-4xl inline-block ml-2 pt-2' />
      </div>
      <p className='px-4 mt-4'>
        If you cannot add me on LinkedIn, you can email me at Paul J Stales (no
        spaces), at gmail.
      </p>
    </section>
  );
};

export default Email;
