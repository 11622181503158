import Hero from './Hero';
import Navbar from './Navbar';

const Header = () => {
  return (
    <>
      <Navbar></Navbar>
      <Hero></Hero>
    </>
  );
};

export default Header;
