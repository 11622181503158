import LinkedIn from '../../Components/LinkedIn';
import Skills from './Skills';

const Resume = () => {
  return (
    <>
      {/* ---------- Skills ---------- */}
      <Skills />
      <div className='divider mb-10' />
      {/* ---------- LinkedIn ---------- */}
      <LinkedIn />
    </>
  );
};

export default Resume;
