import React from 'react';
import FullStack from './FullStack';
import Goals from './Goals';

const Home = () => {
  return (
    <>
      {/* ---------- Full Stack ---------- */}
      <div className='mt-10'>
        <FullStack />
      </div>
      <div className='divider mb-10' />
      {/* ---------- Goals ---------- */}
      <Goals />
    </>
  );
};

export default Home;
