import SectionHeader from '../../Components/SectionHeader';
import TextBoldBlue from '../../Components/TextBoldBlue';
import { FaCloud, FaDatabase, FaLaptop, FaPaintBrush } from 'react-icons/fa';

const FullStack = () => {
  return (
    <section className='px-4 '>
      <SectionHeader customClasses='my-2 mb-4'>A Bit about Me...</SectionHeader>
      <p>
        I love the entire world of software, from the edge of the web to the
        depths of the database.
      </p>
      <p className='mt-4'>
        <TextBoldBlue>
          I am a software engineer with 4 years of experience
        </TextBoldBlue>{' '}
        in both enterprise companies and smaller shops.
      </p>
      <p>
        At my most recent job, one of my projects was seen by over 600,000
        people! (as of January 2023)
      </p>
      {/* stack, backend */}
      <div className='flex flex-col mt-4'>
        <div className='flex'>
          <FaLaptop className='text-2xl font-bold my-2 mr-2' />
          <h3 className='text-xl font-bold my-2'>Backend</h3>
        </div>
        <p>
          I have the most experience with, and prefer to use,{' '}
          <TextBoldBlue>Java with Spring Boot.</TextBoldBlue>
        </p>
        <p>I am familiar with NET (C#, ASP.NET, Entity Framework, etc.).</p>
      </div>
      {/* stack, frontend */}
      <div className='flex flex-col mt-4'>
        <div className='flex'>
          <FaPaintBrush className='text-2xl font-bold my-2 mr-2' />
          <h3 className='text-xl font-bold my-2'>Frontend</h3>
        </div>
        <p>
          I prefer to use <TextBoldBlue>Thymeleaf</TextBoldBlue> or{' '}
          <TextBoldBlue>Angular</TextBoldBlue> with{' '}
          <TextBoldBlue>Tailwind CSS</TextBoldBlue>.
        </p>
        <p>
          I have also used JSP, and react.js (with and without TypeScript) with
          Bootstrap, SASS, and vanilla CSS.
        </p>
      </div>
      {/* stack, persistence */}
      <div className='flex flex-col mt-4'>
        <div className='flex'>
          <FaDatabase className='text-2xl font-bold my-2 mr-2' />
          <h3 className='text-xl font-bold my-2'>Persistence/Database</h3>
        </div>
        <p>
          I am proficient with both{' '}
          <TextBoldBlue>Relational Databases</TextBoldBlue> (PostgreSQL, Oracle,
          SQL Server) and NoSQL databases (MongoDB).
        </p>
        <p>
          I also am familiar with <TextBoldBlue>ORMs</TextBoldBlue> ( Hibernate,
          Entity Framework) and ODMs (Mongoose).
        </p>
      </div>
      {/* stack, cloud */}
      <div className='flex flex-col mt-4'>
        <div className='flex'>
          <FaCloud className='text-2xl font-bold my-2 mr-2' />
          <h3 className='text-xl font-bold my-2'>Cloud</h3>
        </div>
        <p>
          As of 2023 I am dabbling with AWS and plan to become proficient with
          it at work very soon!
        </p>
      </div>
    </section>
  );
};

export default FullStack;
