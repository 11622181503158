import { FaGithub, FaLinkedin, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  const footerYear = new Date().getFullYear();
  return (
    <>
      <footer className='footer footer-center mt-10 p-5 border-t-2 border-blue-400 flex items-center justify-center'>
        {/* copyright */}
        <small className=''>
          Copyright &copy; {footerYear}, Paul J Stales. All rights reserved.
        </small>
        {/* social media links */}
        <div className='flex ml-6'>
          <a
            href='https://www.linkedin.com/in/paul-j-stales/'
            target='_blank'
            rel='noopener noreferrer'
            className=''
            title='Paul J Stales LinkedIn Page'
          >
            <FaLinkedin className='text-xl inline-block mr-4' />
          </a>
          <a
            href='https://twitter.com/pauljstales'
            target='_blank'
            rel='noopener noreferrer'
            className=''
            title='Paul J Stales Twitter Page'
          >
            <FaTwitter className='text-xl inline-block mr-4' />
          </a>
          <a
            href='https://github.com/pauljstales'
            target='_blank'
            rel='noopener noreferrer'
            className=''
            title='Paul J Stales GitHub Page'
          >
            <FaGithub className='text-xl inline-block mr-4' />
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
