import { BrowserRouter, Routes, Route } from 'react-router-dom';
/* Routes */
import About from './routes/about/About';
import Contact from './routes/contact/Contact';
import Home from './routes/home/Home';
import Resume from './routes/resume/Resume';
import NotFound from './routes/notfound/NotFound';
import Recommendations from './routes/recommendations/Recommendations';
import Personal from './routes/personal/Personal';
/* Layout */
import Header from './Layout/Header';
import Footer from './Layout/Footer';
/* Components */
import LookingForWork from './Components/LookingForWork';

function App() {
  // this toggles the looking-for-work component
  // in src\components\LookingForWork.tsx
  const isLookingForWork = false;

  return (
    <div id='main-div' className='bg-cover bg-opacity-0 bg-black'>
      <div className='max-w-6xl mx-auto bg-base-100'>
        <BrowserRouter>
          <Header />
          {isLookingForWork && <LookingForWork />}
          <main className='mx-auto sm:w-11/12 md:w-10/12 lg:w-9/12 xl:w-8/12'>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='about' element={<About />} />
              <Route path='resume' element={<Resume />} />
              <Route path='contact' element={<Contact />} />
              <Route path='personal' element={<Personal />} />
              <Route path='recommendations' element={<Recommendations />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </main>
          <Footer />
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
