/**
 * SubSection Header Props
 * Custom classes allows for additional classes to be added.
 * Children is the standard props.children.
 */
type Props = {
  customClasses?: string;
  children: React.ReactNode;
};

/**
 * SubSection Header
 * The subsection header component is the standard h2 subheader for any subsection.
 * It is a large, bold text inside of an H2 element.
 * @param props, defined above
 * @returns
 */
const SectionHeader: React.FC<Props> = ({ customClasses = '', children }) => {
  return <h2 className={`text-2xl font-bold ${customClasses}`}>{children}</h2>;
};

export default SectionHeader;
