import { useState } from 'react';
import { quotes } from '../../data/RecQuotes';

const Quotes = () => {
  const [quote, setQuote] = useState(
    '"He has a level of passion that can’t be taught or externally inspired." - Tom Shawver, CTO at Booksio, 360 Media Direct'
  );

  return (
    <div className='flex flex-col items-center md:items-center space-x-0 space-y-4'>
      <button
        className='btn btn-primary w-full'
        onClick={() => {
          setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
        }}
      >
        Click for Another Recommendation!
      </button>
      <blockquote className='font-serif w-full md:w-4/5 px-8 py-4 border border-1 border-l-8 rounded-xl border-blue-100 h-60 flex align-center items-center'>
        <span aria-live='polite'>{quote}</span>
      </blockquote>
    </div>
  );
};

export default Quotes;
