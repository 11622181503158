import LinkedIn from '../../Components/LinkedIn';
import Email from './Email';
import SectionHeader from '../../Components/SectionHeader';

const Contact = () => {
  return (
    <>
      {/* ---------- Contact Me ---------- */}
      <section className='mt-10 px-4'>
        <SectionHeader>Contacting Me</SectionHeader>
        <p className='mt-4'>
          There are several ways to contact me,{' '}
          <span className='font-bold text-white'>
            but the best way to contact me is via LinkedIn
          </span>
          .
        </p>
      </section>
      <div className='divider mb-10' />
      {/* ---------- LinkedIn ---------- */}
      <LinkedIn />
      <div className='divider mb-10' />
      {/* ---------- eMail ---------- */}
      <Email />
    </>
  );
};

export default Contact;
