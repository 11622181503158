import { NavLink } from 'react-router-dom';

const NavLinks = () => {
  return (
    <>
      <li>
        {/* ---------- ---------- Link 1 - Home ---------- ---------- */}

        <NavLink
          to='/'
          className={({ isActive }) => (isActive ? 'text-white' : '')}
        >
          <p className='px-2 font-bold hover:underline mb-1 sm:mb-0'>Home</p>
        </NavLink>
      </li>
      <li>
        {/* ---------- ---------- Link 2 - About ---------- ---------- */}
        <NavLink
          to='/about'
          className={({ isActive }) => (isActive ? 'text-white' : '')}
        >
          <p className='px-2 font-bold hover:underline mb-1 sm:mb-0'>About</p>
        </NavLink>
      </li>
      <li>
        {/* ---------- ---------- Link 3 - Recommendations ---------- ---------- */}
        <NavLink
          to='/recommendations'
          className={({ isActive }) => (isActive ? 'text-white' : '')}
        >
          <p className='px-2 font-bold hover:underline mb-1 sm:mb-0'>
            Recommendations
          </p>
        </NavLink>
      </li>
      <li>
        {/* ---------- ---------- Link 4 - Resume ---------- ---------- */}
        <NavLink
          to='/resume'
          className={({ isActive }) => (isActive ? 'text-white' : '')}
        >
          <p className='px-2 font-bold hover:underline mb-1 sm:mb-0'>Resume</p>
        </NavLink>
      </li>
      <li>
        {/* ---------- ---------- Link 5 - Contact ---------- ---------- */}
        <NavLink
          to='/contact'
          className={({ isActive }) => (isActive ? 'text-white' : '')}
        >
          <p className='px-2 font-bold hover:underline mb-1 sm:mb-0'>Contact</p>
        </NavLink>
      </li>
    </>
  );
};

export default NavLinks;
