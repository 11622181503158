import bgImage from '../assets/images/hero.png';

const Hero = () => {
  return (
    <div
      className='bg-cover bg-center w-full h-72 flex flex-col items-center justify-around space-y-10 '
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <h1 className='text-6xl font-bold text-center'>Paul J Stales</h1>
      <h2 className='text-4xl font-bold text-center'>Software Engineer</h2>
    </div>
  );
};

export default Hero;
