import Navlinks from './Navlinks';
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='navbar bg-base-100'>
      {/* ---------- ---------- ---------- NAVBAR, LEFT ---------- ---------- ---------- */}
      <div className='navbar-start w-full'>
        {/* NAVBAR, LEFT, Nav Menu Dropdown on XS */}
        <div className='dropdown sm:hidden '>
          <label tabIndex={0} className='btn btn-ghost'>
            <svg
              role='img'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              className='inline-block w-5 h-5 stroke-current'
            >
              <text className='hidden' fontSize='0'>
                Navigation menu icon
              </text>
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M4 6h16M4 12h16M4 18h16'
              ></path>
            </svg>
          </label>
          <ul
            tabIndex={0}
            className='menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52'
          >
            <Navlinks></Navlinks>
          </ul>
        </div>
        {/* NAVBAR, LEFT, Nav Menu Flex on sm+ */}
        <div className='navbar-start hidden sm:flex w-full'>
          <ul className='menu menu-horizontal px-1'>
            <Navlinks></Navlinks>
          </ul>
        </div>
      </div>

      {/* ---------- ---------- ---------- NAVBAR, CENTER ---------- ---------- ---------- */}
      <div className='navbar-center w-10'></div>

      {/* ---------- ---------- ---------- NAVBAR, END ---------- ---------- ---------- */}
      <div className='navbar-end'>
        <Link to={'/personal'}>
          <div className='text-2xl font-bold group motion-safe:hover:-rotate-6 duration-300'>
            <span className='group-hover:uppercase group-hover:text-3xl'>
              p
            </span>
            <span className='hidden group-hover:inline text-md text-gray-600'>
              aul
            </span>
            <span className='group-hover:hidden text-md'>.</span>
            <span className='group-hover:uppercase group-hover:text-3xl'>
              js
            </span>
            <span className='hidden group-hover:inline text-md text-gray-600'>
              tales
            </span>
          </div>
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
