import { FaLinkedin } from 'react-icons/fa';
import SectionHeader from './SectionHeader';

/**
 * The LinkedIn Component encourages the reader to visit my LinkedIn
 * for my complete resume, experience, skills, etc. It is used on
 * several of the pages.
 * @returns
 */
const LinkedIn = () => {
  return (
    <section className='flex flex-col px-4 mt-10'>
      {/* header */}
      <div className='flex'>
        <SectionHeader>Linked</SectionHeader>
        <FaLinkedin className='text-5xl inline-block pb-1' />
      </div>
      {/* content */}
      <div className='flex flex-col space-y-4 mt-4'>
        <p>Are you looking for my full resume?</p>
        <p>
          You can find my experience, skill set, recommendations, and more at my
          always up-to-date{' '}
          <a
            href='https://www.linkedin.com/in/paul-j-stales/'
            target='_blank'
            rel='noopener noreferrer'
            className='underline font-bold text-white'
            title='Paul J Stales LinkedIn Page'
          >
            LinkedIn
          </a>{' '}
          profile. Send me a message or connection request!
        </p>
      </div>

      {/* Recommendations Section */}
    </section>
  );
};

export default LinkedIn;
