import Story from './Story';

const About = () => {
  return (
    <>
      {/* ---------- Story of p.js ---------- */}
      <div className='mt-10'>
        <Story />
      </div>
    </>
  );
};

export default About;
