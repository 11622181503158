import {
  FaJsSquare,
  FaCss3,
  FaDatabase,
  FaLaptop,
  FaJava,
  FaAngular,
  FaCloud,
} from 'react-icons/fa';
import SectionHeader from '../../Components/SectionHeader';

const Skills = () => {
  return (
    <section className='flex flex-col px-4 mt-10'>
      {/* ********** ********** section header ********** ********** */}
      <SectionHeader>Skills</SectionHeader>
      {/* ********** ********** section content ********** ********** */}
      <div className='flex flex-col space-y-4 mt-4'>
        {/* ---------- skill 1 - Java Technologies ---------- */}
        <div>
          <FaJava className='inline-block mr-2 text-3xl text-blue-300'></FaJava>
          <span className='font-bold text-white uppercase'>
            Java Technologies
          </span>
          : Java, Servlets/JSP, Spring, Spring Boot, JPA/Hibernate, Junit,
          Mockito
        </div>
        {/* ---------- skill 2 - Cloud ---------- */}
        <div>
          <FaCloud className='inline-block mr-2 text-3xl text-white'></FaCloud>
          <span className='font-bold text-white uppercase'>Cloud</span>: Working
          on learning more about Amazon Web Services (aws) every day!
        </div>
        {/* ---------- skill 3 - Databases ---------- */}
        <div>
          <FaDatabase className='inline-block mr-2 text-3xl text-pink-300'></FaDatabase>
          <span className='font-bold text-white uppercase'>Databases</span>:
          Relational/SQL (
          <span className='italic'>
            Postgres, Oracle, SQL Server, various ORMs
          </span>
          ), NoSQL (<span className='italic'>Mongo, Mongoose</span>)
        </div>
        {/* ---------- skill 4 - core web ---------- */}
        <div className=''>
          <FaJsSquare className='inline-block mr-2 text-3xl text-yellow-400'></FaJsSquare>
          <span className='font-bold text-white uppercase'>Core Web</span>:
          TypeScript, ES6, JavaScript, JSON, HTML5
        </div>
        {/* ---------- skill 5 - Front-End ---------- */}
        <div>
          <FaAngular className='inline-block mr-2 text-3xl text-red-600'></FaAngular>
          <span className='font-bold text-white uppercase'>
            Frontend Frameworks:
          </span>{' '}
          Angular, as well as react (
          <span className='italic'>redux, jest, testing library, etc.</span>)
        </div>

        {/* ---------- skill 6 - design ---------- */}
        <div className=''>
          <FaCss3 className='inline-block mr-2 text-3xl text-blue-400'></FaCss3>
          <span className='font-bold text-white uppercase'>Design</span>: CSS3,
          Tailwind CSS, SASS, Bootstrap
        </div>

        {/* ---------- skill 7 - Miscellaneous ---------- */}
        <div>
          <FaLaptop className='inline-block mr-2 text-3xl text-orange-400'></FaLaptop>
          <span className='font-bold text-white uppercase'>Miscellaneous</span>:
          Design Patterns , Version control (
          <span className='italic'>Git/GitHub</span>), Tools (
          <span className='italic'>NPM, Postman, Jira</span>), Methodologies (
          <span className='italic'>SDLC, TDD, Waterfall, Agile, Scrum</span>){' '}
        </div>
      </div>
    </section>
  );
};

export default Skills;
