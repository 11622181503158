import { FaThumbsUp } from 'react-icons/fa';
import Quotes from './Quotes';
import SectionHeader from '../../Components/SectionHeader';

const Recommendation = () => {
  return (
    <section className='flex flex-col px-4 mt-10'>
      {/* section header */}
      <div className='flex'>
        <FaThumbsUp className='text-4xl inline-block mr-2' />
        <SectionHeader>Recommendations</SectionHeader>
      </div>
      {/* section content */}
      <div className='flex flex-col space-y-4 mt-4'>
        <p>
          My colleagues have given me many positive{' '}
          <a
            href='https://www.linkedin.com/in/paul-j-stales/details/recommendations/'
            target='_blank'
            rel='noopener noreferrer'
            className='underline font-bold text-white'
            title='Paul J Stales LinkedIn Page'
          >
            recommendations
          </a>{' '}
          on LinkedIn!
        </p>
        <Quotes />
      </div>
    </section>
  );
};

export default Recommendation;
