/**
 * Section Header Props
 * Custom classes allows for additional classes to be added.
 * Children is the standard props.children.
 */
type Props = {
  customClasses?: string;
  children: React.ReactNode;
};

/**
 * Section Header
 * The section header component is the standard header for any section.
 * It is a large, bold text inside of an H1 element.
 * @param props, defined above
 * @returns
 */
const SectionHeader: React.FC<Props> = ({ customClasses = '', children }) => {
  return <h1 className={`text-4xl font-bold ${customClasses}`}>{children}</h1>;
};

export default SectionHeader;
